<template>
  <div>
    <div class="ContentHeader">
      <div class="HeaderAll">
        <!-- Slot Breadcrumb -->
        <slot
          name="titlePesertaBreadcrumb"
          :messageProps="store.nama_peserta"
        ></slot>

        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{
                name: item.nameroute,
                params: item.param,
                query: item.query,
              }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <div class="buttondetailpeserta" v-if="$route.query.from != 'Dashboard'">
        <div v-if="$route.query.status != 'coming-movement'">
          <span v-if="store.movement == 'DELETE'">
            <a-button
              type="linkcustom"
              @click="
                actionall(
                  'Reaktif',
                  store.id,
                  store.nama_peserta,
                  store.id_job_order_perusahaan
                )
              "
            >
              Reaktif
              <img :src="require('@/assets/img/icon/reaktif.svg')" />
            </a-button>
          </span>
          <span v-else>
            <a-button
              type="linkcustom"
              @click="
                actionall(
                  'Change',
                  store.id,
                  store.nama_peserta,
                  store.id_job_order_perusahaan
                )
              "
            >
              Change
              <img :src="require('@/assets/img/icon/change.svg')" />
            </a-button>
            <a-button
              type="linkcustom"
              @click="
                actionall(
                  'Reprint',
                  store.id,
                  store.nama_peserta,
                  store.id_job_order_perusahaan
                )
              "
            >
              Reprint
              <img :src="require('@/assets/img/icon/reprint.svg')" />
            </a-button>

            <a-button
              type="linkcustom"
              @click="
                actionall(
                  'Mutasi',
                  store.id,
                  store.nama_peserta,
                  store.id_job_order_perusahaan
                )
              "
            >
              Mutasi
              <img :src="require('@/assets/img/icon/mutasi.svg')" />
            </a-button>

            <a-button
              type="linkcustom"
              @click="
                actionall(
                  'Delete',
                  store.id,
                  store.nama_peserta,
                  store.id_job_order_perusahaan
                )
              "
            >
              Delete
              <img :src="require('@/assets/img/icon/delete.svg')" />
            </a-button>
          </span>
        </div>
      </div>
    </div>

    <div class="allcard">
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <a-row :gutter="[20, 20]">
          <a-col :lg="17">
            <div class="claimcard">
              <h1>Informasi Peserta</h1>
              <a-row>
                <a-col :lg="8">
                  <div class="sec-data-pasien">
                    <div>
                      <label>No. Peserta</label>
                      <p>
                        {{ `${store.no_peserta}` }}
                      </p>
                    </div>
                    <div>
                      <label>No. Kartu</label>
                      <p>
                        {{ `${store.no_kartu}` }}
                      </p>
                    </div>
                    <div>
                      <label>Nama Peserta</label>
                      <p>
                        {{ `${store.nama_peserta}` }}
                      </p>
                    </div>

                    <div>
                      <label>NIK</label>
                      <p>
                        {{
                          store.nik == null || store.nik == "" ? "-" : store.nik
                        }}
                      </p>
                    </div>

                    <div>
                      <label>Nama Karyawan</label>
                      <p>{{ store.nama_karyawan }}</p>
                    </div>
                  </div>
                  <div class="sec-data-pasien">
                    <div>
                      <label>Jenis Kelamin</label>
                      <p>
                        <a-icon
                          :type="
                            store.jenis_kelamin == 'Pria' ? 'man' : 'woman'
                          "
                          :style="
                            store.jenis_kelamin == 'Pria'
                              ? ''
                              : 'color: #DF5684'
                          "
                        />
                        {{ store.jenis_kelamin }}
                      </p>
                    </div>
                  </div>
                </a-col>
                <a-col :lg="8">
                  <div class="sec-data-pasien">
                    <div>
                      <label>Tgl Lahir</label>
                      <p><a-icon type="calendar" /> {{ store.tgl_lahir }}</p>
                    </div>
                    <div>
                      <label>Status</label>
                      <p>
                        {{ `${store.status}` }}
                      </p>
                    </div>

                    <div>
                      <label>No.Telp</label>
                      <p>
                        {{ `${store.no_telp}` }}
                      </p>
                    </div>
                    <div>
                      <label>Email</label>
                      <p>
                        {{
                          store.alamat_email == null ? "-" : store.alamat_email
                        }}
                      </p>
                    </div>

                    <div>
                      <label>Tgl Efektif</label>
                      <p v-if="store.tgl_efektif != null">
                        <a-icon type="calendar" /> {{ store.tgl_efektif }}
                      </p>
                      <p else>-</p>
                    </div>
                  </div>
                </a-col>
                <a-col :lg="8">
                  <div class="sec-data-pasien">
                    <div>
                      <label>Benefit</label>
                      <div>
                        <a-dropdown
                          :trigger="['click']"
                          placement="topRight"
                          v-for="(item, i) in plan"
                          :key="i"
                        >
                          <a-button
                            type="plan"
                            @click="
                              planshow(
                                store.id_job_order,
                                item.plan,
                                item.value
                              )
                            "
                            style="
                              display: block;
                              margin-bottom: 15px !important;
                              width: 120px;
                            "
                            ><img
                              :src="
                                require(`@/assets/img/icon/${item.icon}.svg`)
                              "
                            />{{ `${item.plan} - ${item.value}` }}</a-button
                          >

                          <div slot="overlay" class="overlayplan">
                            <div
                              v-if="databenefit.length > 0"
                              style="
                                max-height: 240px;
                                overflow-y: auto;
                                padding: 20px;
                              "
                            >
                              <table style="">
                                <thead>
                                  <tr>
                                    <td>Manfaat</td>
                                    <td>Benefit</td>
                                    <td>Plan {{ databenefit[0].val }}</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, i) in databenefit.filter(
                                      (key) => key.rowid > 1
                                    )"
                                    :key="i"
                                  >
                                    <td>{{ item.manfaat_plan }}</td>
                                    <td>{{ item.maksimal_limit }}</td>
                                    <td>{{ item.val }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <a-empty v-else />
                          </div>
                        </a-dropdown>
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>

            <div class="claimcard">
              <h1>Bank Account</h1>
              <a-row>
                <a-col :lg="8">
                  <div class="sec-data-pasien">
                    <div>
                      <label>Nama Bank</label>
                      <p>
                        {{ store.nama_bank == null ? "-" : store.nama_bank }}
                      </p>
                    </div>
                  </div>
                </a-col>
                <a-col :lg="8">
                  <div class="sec-data-pasien">
                    <div>
                      <label>No. Rekening</label>
                      <p>
                        {{
                          store.no_rekening == null ? "-" : store.no_rekening
                        }}
                      </p>
                    </div>
                  </div>
                </a-col>
                <a-col :lg="8">
                  <div class="sec-data-pasien">
                    <div>
                      <label>Nama Pemilik Rekening</label>
                      <p>
                        {{
                          store.rekening_atas_nama == null
                            ? "-"
                            : store.rekening_atas_nama
                        }}
                      </p>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>

            <div class="claimcard">
              <h1>Company Information</h1>
              <a-row>
                <a-col :lg="8">
                  <div class="sec-data-pasien">
                    <div>
                      <label>Nama Group</label>
                      <p>{{ store.nama_group }}</p>
                    </div>

                    <div>
                      <label>Nama Perusahaan</label>
                      <p>{{ store.nama_perusahaan }}</p>
                    </div>
                  </div>
                </a-col>
                <a-col :lg="8">
                  <div class="sec-data-pasien">
                    <div>
                      <label>Cabang</label>
                      <p>{{ store.cabang }}</p>
                    </div>
                    <div>
                      <label>Sub Cabang I</label>
                      <p>
                        {{
                          store.sub_cabang_satu == null
                            ? "-"
                            : store.sub_cabang_satu
                        }}
                      </p>
                    </div>
                    <div>
                      <label>Sub Cabang II</label>
                      <p>
                        {{
                          store.sub_cabang_dua == null
                            ? "-"
                            : store.sub_cabang_dua
                        }}
                      </p>
                    </div>
                  </div>
                </a-col>
                <a-col :lg="8">
                  <div class="sec-data-pasien">
                    <div>
                      <label>Keterangan</label>
                      <p>{{ store.ket == null ? "-" : store.ket }}</p>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :lg="7">
            <div class="claimcard">
              <div class="historyRevisi" v-if="dateChange.length > 0">
                <h1>History Revisi</h1>

                <div v-for="(item, i) in dateChange" :key="i">
                  <div
                    class="histor_level_1"
                    v-if="item.movement_baru == 'CHANGE'"
                  >
                    <div>
                      <div>
                        <img :src="require('@/assets/img/icon/edit.svg')" />
                        Change
                      </div>
                      <div>{{ item.tgl_efektif }}</div>
                    </div>

                    <div class="tablehistory">
                      <table>
                        <thead>
                          <tr>
                            <td>Value Name</td>
                            <td>Old Value</td>
                            <td>New Value</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(items, ii) in item.history" :key="ii">
                            <td>{{ items.column }}</td>
                            <td>{{ items.old_value }}</td>
                            <td>{{ items.new_value }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    class="histor_level_0"
                    v-if="item.movement_baru == 'DELETE'"
                  >
                    <div>
                      <img :src="require('@/assets/img/icon/delete.svg')" />
                      Delete
                    </div>
                    <div>{{ item.tgl_efektif }}</div>
                  </div>
                  <div
                    class="histor_level_0"
                    v-if="item.movement_baru == 'REAKTIF'"
                  >
                    <div>
                      <img :src="require('@/assets/img/icon/reaktif.svg')" />
                      Reactive
                    </div>
                    <div>{{ dateReaktif == null ? "-" : dateReaktif }}</div>
                  </div>

                  <div
                    class="histor_level_0"
                    v-if="item.movement_baru == 'REPRINT'"
                  >
                    <div>
                      <img :src="require('@/assets/img/icon/reprint.svg')" />
                      Reprint
                    </div>
                    <div>{{ item.tgl_efektif }}</div>
                  </div>
                  <div
                    class="histor_level_0"
                    v-if="item.movement_baru == 'MUTASI'"
                  >
                    <div>
                      <img :src="require('@/assets/img/icon/mutasi.svg')" />
                      Mutasi
                    </div>
                    <div>{{ item.tgl_efektif }}</div>
                  </div>
                </div>
              </div>
              <div class="historyRevisi" v-else>
                <h1>History Revisi</h1>
                <a-empty />
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="[15, 15]">
          <a-col :lg="24">
            <HistoryClaim ref="list" />
          </a-col>
        </a-row>
      </simplebar>
    </div>

    <ModalAction ref="action" />
  </div>
</template>
<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import HistoryClaim from "@/components/Claim/list.vue";
import ModalAction from "@/components/Modal/action.vue";
export default {
  components: {
    ModalAction,
    HistoryClaim,
    simplebar,
  },
  data() {
    return {
      store: {},
      plan: [],
      databenefit: [],
      HistoryRevisi: [],
      dateChange: null,
      dateDelete: null,
      dateReaktif: null,
      dateReprint: null,
      dateMutasi: null,
      breadcrumb: [
        {
          name: "Daftar Group",
          nameroute: "ListDaftarGroup",
          param: {},
        },
        {
          name: "Data Peserta",
          nameroute: "ListDataPeserta",
          param: { id: this.$route.params.id },
        },
        {
          name: "Detail Peserta",
          nameroute: "",
          param: {},
        },
      ],
      familyPlan: "",
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from);

      if (from.name != null) {
        if (from.meta.title == "Data Peserta") {
          vm.breadcrumb = [
            {
              name: "Daftar Group",
              nameroute: "ListDaftarGroup",
              param: {},
            },
            {
              name: from.meta.title,
              nameroute: from.name,
              param: { id: from.params.id },
              query: from.query,
            },
            {
              name: "Detail Peserta",
              nameroute: "",
              param: {},
            },
          ];
        } else {
          vm.breadcrumb = [
            {
              name: from.meta.title,
              nameroute: from.name,
              param: { id: from.params.id },
              query: from.query,
            },
            {
              name: "Detail Peserta",
              nameroute: "",
              param: {},
            },
          ];
        }
      } else {
        vm.breadcrumb = [
          {
            name: "Daftar Group",
            nameroute: "ListDaftarGroup",
            param: {},
          },
          {
            name: "Data Peserta",
            nameroute: "ListDataPeserta",
            param: { id: vm.$route.params.id },
          },
          {
            name: "Detail Peserta",
            nameroute: "",
            param: {},
          },
        ];
      }
    });
  },

  mounted() {
    this.fetch();
    this.history();

    this.$refs.list.getPosition(this.$refs.list);
  },

  methods: {
    actionall(e, id, namapeserta, idJobOrder) {
      if (e == "Change") {
        this.$router.push({
          name: "EditPeserta",
          params: { id: this.$route.params.id, idpeserta: id },
        });
      } else {
        if (e == "Mutasi") {
          if (this.store.status == "Employee") {
            this.$refs.action.Show(e, id, namapeserta, idJobOrder);
          } else {
            this.$message.error(
              "Mutasi hanya bisa dilakukan dengan status employe"
            );
          }
        } else {
          this.$refs.action.Show(e, id, namapeserta, idJobOrder);
        }
      }
    },
    planshow(id, plan, value) {
      this.loadingbenefit = true;
      let val = value.replace(/\s/g, "");

      this.$store
        .dispatch("getBenefit", {
          id: id,
          plan: plan,
          value: value,
          family: this.familyPlan,
        })
        .then((response) => {
          this.loadingbenefit = false;
          this.databenefit = response.data.data;

          // console.log(this.databenefit);
        });
    },

    history() {
      // this.$store
      //   .dispatch("GetHistoryRevisi", {
      //     id: this.$route.params.idpeserta,
      //   })
      //   .then((response) => {
      //     this.HistoryRevisi = response.data.data;
      //   });

      this.$store
        .dispatch("GetHistoryLog", {
          id: this.$route.params.idpeserta,
        })
        .then((response) => {
          console.log(response.data.data);
          this.dateChange = response.data.data;
        });

      // this.$store
      //   .dispatch("GetHistoryLog", {
      //     id: this.$route.params.idpeserta,
      //     movement: "REAKTIF",
      //   })
      //   .then((response) => {
      //     this.dateReaktif = response.data.data;
      //   });

      // this.$store
      //   .dispatch("GetHistoryLog", {
      //     id: this.$route.params.idpeserta,
      //     movement: "REPRINT",
      //   })
      //   .then((response) => {
      //     this.dateReprint = response.data.data;
      //   });

      // this.$store
      //   .dispatch("GetHistoryLog", {
      //     id: this.$route.params.idpeserta,
      //     movement: "MUTASI",
      //   })
      //   .then((response) => {
      //     this.dateMutasi = response.data.data;
      //   });
    },
    fetch() {
      if (this.$route.query.from == "Dashboard") {
        this.$store
          .dispatch("GetDetailPesertaDashboard", {
            id: this.$route.params.idpeserta,
          })
          .then((response) => {
            this.store = response.data.data;

            const preplan = [
              {
                icon: "ri",
                plan: "RI",
                value: response.data.data.ri,
              },
              {
                icon: "rj",
                plan: "RJ",
                value: response.data.data.rj,
              },
              {
                icon: "rb",
                plan: "RB",
                value: response.data.data.mt,
              },
              {
                icon: "rg",
                plan: "RG",
                value: response.data.data.rg,
              },
              {
                icon: "kc",
                plan: "KC",
                value: response.data.data.kc,
              },
            ];
            this.plan = preplan.filter((key) => key.value != null);
          });
      } else {
        this.$store
          .dispatch("GetDetailPeserta", {
            id: this.$route.params.idpeserta,
            status: this.$route.query.status,
          })
          .then((response) => {
            //console.log("lembong", response.data.data);
            this.store = response.data.data;

            this.familyPlan = response.data.data.family_plan;

            console.log(this.familyPlan);

            const preplan = [
              {
                icon: "ri",
                plan: "RI",
                value: response.data.data.ri,
              },
              {
                icon: "rj",
                plan: "RJ",
                value: response.data.data.rj,
              },
              {
                icon: "rb",
                plan: "RB",
                value: response.data.data.mt,
              },
              {
                icon: "rg",
                plan: "RG",
                value: response.data.data.rg,
              },
              {
                icon: "kc",
                plan: "KC",
                value: response.data.data.kc,
              },
            ];
            this.plan = preplan.filter((key) => key.value != null);
          });
      }
    },
  },
};
</script>
